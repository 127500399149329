import React from 'react';
import IntroImg from "../assets/intro-bg.jpeg"
import IntroImg2 from "../assets/sambarrice.jpeg"
import IntroImg3 from "../assets/pulihora.jpeg"
import IntroImg4 from "../assets/Vegetable Rice.jpeg"
import "./MenuItemsStyles.css"


const MenuItems = () => {
  // Sample menu items, replace with your actual menu items
  const menuItems = [
    {
      id: 1,
      name: 'Curd Rice',
      description: 'Curd Rice',
      price: '$10',
      image: IntroImg,
    },
    {
      id: 2,
      name: 'Sambar Rice',
      description: 'Sambar Rice',
      price: '$15',
      image: IntroImg2,
    },
    {
      id: 3,
      name: 'Pulihora Rice',
      description: 'Pulihora Rice',
      price: '$10',
      image: IntroImg3,
    },
    {
      id: 4,
      name: 'Vegetable Rice',
      description: 'Vegetable Rice',
      price: '$10',
      image: IntroImg4,
    },
    // Add more menu items as needed
  ];

  return (
    <div className="menu">
      {/* <h2>Menu</h2> */}
      <div className="menu-items">
        {menuItems.map(item => (
          <div key={item.id} className="menu-item">
            <img src={item.image} alt={item.name} />
            <p>{item.description}</p>
            {/* <p>{item.price}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuItems;
