import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Form from '../components/Form'
import HeroImg3 from '../components/HeroImg3'


const Contact = () => {
  return (
    <div>
      <Navbar />
      <HeroImg3 />
      <Form />
      <Footer />
    </div>
  )
}

export default Contact