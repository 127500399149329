import React from 'react'
import Navbar from '../components/Navbar'
import HeroImg from '../components/HeroImg'
import Footer from '../components/Footer'
import MenuItems from '../components/MenuItems'
import Form from '../components/Form'

const Home = () => {
  return (
    <div>
        <Navbar />
        <HeroImg />
        <MenuItems />
        <Form />
        <Footer />
    </div>
  )
}

export default Home