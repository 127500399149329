import React from 'react'
import "./HeroImg2Styles.css"
import IntroImg from "../assets/sambarrice.jpeg"
// import { Link } from "react-router-dom"


const HeroImg3 = () => {
  return (
    <div className='hero'>
        <div className='mask'>
            <img className="intro-img" src={IntroImg} alt="IntroImg" />
        </div>
        <div className='content'>
            <h1>Contact</h1>
            {/* <p>Tasty and Class</p>
            <br></br>
            <p>The full site will be launched soon.</p> */}
            {/* <div>
                <Link to="/menu" className='btn'>Menu</Link>
            </div>
            <div>
                <Link to="/contact" className='btn'>Contact</Link>
            </div> */}
        </div>
    </div>
  )
}

export default HeroImg3