import React from 'react'
import "./FooterStyles.css"
import { FaFacebook, FaHome, FaMailBulk, FaPhone } from 'react-icons/fa'
import { FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa6'

const Footer = () => {
  return (
    <div className='footer'>
       <div className='footer-container'>
        <div className='left'>
            <div className='location'>
                <FaHome size={20} style={{color:"#fff",marginRight:"2rem"}} />
                <div>
                <p>#Address</p>
                <p>andhra pradesh</p>
            </div>
        </div>
        <div className='phone'>
            <h4><FaPhone size={20} style={{color:"#fff",marginRight:"2rem"}} />#12345678</h4>   
        </div>
        <div className='email'>
            <h4><FaMailBulk size={20} style={{color:"#fff",marginRight:"2rem"}} />amruthfoods24@gmail.com</h4>   
        </div>
        </div>    
        <div className='right'>
            <h4>About Amruth Food's</h4>
            <p>this is me K Mohan.CEO & Founder of Amruth Food's.</p>
            <div className='social'>
            <FaFacebook size={20} style={{color:"#fff",marginRight:"2rem"}} />
            <FaTwitter size={20} style={{color:"#fff",marginRight:"2rem"}} />
            <FaYoutube size={20} style={{color:"#fff",marginRight:"2rem"}} />
            <FaLinkedin size={20} style={{color:"#fff",marginRight:"2rem"}} />
            </div>
        </div>

       </div>
    </div>
  )
}

export default Footer